var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLargeBanner
    ? _c("div", [
        _c(
          "div",
          {
            staticClass:
              "overview-banner flex bg-adori-light-gray br2 justify-between items-center light-gray ba b--adori-gray",
            class: _vm.isLargeBanner ? "pa5 mb4" : "pa5 mb3",
          },
          [
            _c(
              "div",
              {
                staticClass: "banner-left flex w-50 items-center",
                class: _vm.isLargeBanner ? "" : "pr4",
              },
              [_c("img", { attrs: { src: _vm.bannerImageUrl, alt: "" } })]
            ),
            _c("div", { staticClass: "w-50 flex" }, [
              _c("div", { staticClass: "banner-right" }, [
                _c("h1", { staticClass: "mt0" }, [
                  _vm._v(_vm._s(_vm.bannerHeader)),
                ]),
                _c("p", {
                  staticClass: "lh-copy silver",
                  domProps: { innerHTML: _vm._s(_vm.bannerSubHeader) },
                }),
                _vm.bannerLocation === "ALL_AUDIOS"
                  ? _c(
                      "div",
                      { staticClass: "flex justify-between" },
                      [
                        _vm.$permissions.isCreateEpisodeAllowed()
                          ? _c("BaseButtonRed", {
                              staticClass: "add-podcast mr2",
                              attrs: {
                                text: "Upload a new track",
                                onClick: _vm.handleUploadAudio,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm.bannerLocation === "ALL_TAGS"
                  ? _c(
                      "div",
                      [
                        _vm.$permissions.isCreateTagShowAllowed("")
                          ? _c("BaseButtonRed", {
                              staticClass: "add-podcast mr2",
                              attrs: {
                                text: "Create a new tag",
                                onClick: _vm.createNewTag,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm.bannerLocation === "ALL_INSIGHTS"
                  ? _c(
                      "div",
                      [
                        _vm.$permissions.isViewInsightsShowAllowed("")
                          ? _c("BaseButtonRed", {
                              staticClass: "add-podcast mr2 ph4",
                              attrs: {
                                text: "Adori Insights demo",
                                onClick: _vm.handleDemoInsights,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      { staticClass: "flex justify-between" },
                      [
                        _vm.$permissions.isCreatePodcastAllowed()
                          ? _c("BaseButtonRed", {
                              staticClass: "add-podcast mr2 ph4",
                              attrs: {
                                text: "Create a new podcast",
                                onClick: _vm.handleCreatePodcast,
                              },
                            })
                          : _vm._e(),
                        _vm.$permissions.isCreatePodcastAllowed()
                          ? _c("BaseButtonBorder", {
                              staticClass: "add-podcast b-adori-red",
                              attrs: {
                                text: "Transfer an existing podcast",
                                onClick: _vm.handleImportPodcast,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
              ]),
            ]),
          ]
        ),
      ])
    : _c("div", [
        _c(
          "div",
          {
            staticClass:
              "pa4 bg-adori-light-gray br2 mb4 flex flex-column ba b--adori-gray",
          },
          [
            _c("div", { staticClass: "f4 mb4 light-gray lh-title tc" }, [
              _vm._v("Publish a podcast"),
            ]),
            _vm._m(0),
            _vm._m(1),
            _c(
              "div",
              [
                _vm.$permissions.isCreatePodcastAllowed()
                  ? _c("BaseButtonRed", {
                      staticClass: "mb3 w-100",
                      attrs: {
                        text: "Create a new podcast",
                        onClick: _vm.handleCreatePodcastNoRedirect,
                      },
                    })
                  : _vm._e(),
                _vm.$permissions.isPublishPodcastShowAllowed("")
                  ? _c("BaseButtonBorder", {
                      staticClass: "w-100",
                      attrs: {
                        text: "View all podcast shows",
                        onClick: _vm.handlePublish,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
      ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mb2 flex items-center" }, [
      _c("img", {
        staticStyle: { width: "150px", margin: "auto", height: "115px" },
        attrs: { src: require("@/assets/podcast_image_svg.svg"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "w-100 lh-13 silver custom-font-size" }, [
      _c("div", { staticClass: "mb-custom-px" }, [
        _vm._v("Manage unlimited podcast shows."),
      ]),
      _c("div", { staticClass: "mb-custom-px" }, [
        _vm._v(
          "Distribute shows to all leading podcast directories and platforms."
        ),
      ]),
      _c("div", { staticClass: "mb-custom-px" }, [
        _vm._v("Embed interactive web-players of a podcast on any website."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }