





















































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

@Component
export default class OverviewBanner extends Vue {
  @Prop(Boolean) isLargeBanner!: boolean
  @Prop(String) bannerHeader!: string
  @Prop(String) bannerSubHeader!: string
  @Prop(String) bannerImageUrl!: string
  @Prop(String) bannerLocation!: string
  @Action showCreatePodcast!: any
  @Action showImportPodcast!: any

  handleCreatePodcast() {
    this.$router.push('/audio')
    this.showCreatePodcast()
  }

  handleCreatePodcastNoRedirect() {
    this.showCreatePodcast()
  }

  handleImportPodcast() {
    this.$router.push('/audio')
    this.showImportPodcast()
  }

  handleUploadAudio() {
    this.$store.dispatch('uploadNewAudioTrack')
  }

  createNewTag() {
    this.$store.dispatch('showTagEdit', null)
  }

  handleDemoInsights() {
    window.open('https://demo.adorilabs.com/insights', '_blank')
  }

  handlePublish() {
    this.$router.push('./publish')
  }
}
